import * as React from "react";

import { isEmpty } from "lodash";
import { connect } from "react-redux";

import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { RdvButton } from "./rdv-button";
import { isValidEmail } from "../../utilities/is-valid-email";
import { SetToastMessageAction, SetToastMessagePayload } from "../../redux/toast/types";
import { TOAST_ALERT } from "../../redux/toast/constants";
import { SetAuthStateAction, SetAuthStatePayload, SignInWithEmailAndPasswordAction, SignInWithEmailAndPasswordPayload } from "../../redux/authentication/types";
import { Router } from "../../utilities/router";

interface MapStateProps {
  password: string;
  email: string;
  inProgress: boolean;
}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  signInWithEmailAndPassword: (paylaod: SignInWithEmailAndPasswordPayload) => SignInWithEmailAndPasswordAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

type Props = MapDispatchProps & MapStateProps;


export const Component = (props: Props) => {
  const { email, password, inProgress, setAuthState, signInWithEmailAndPassword, setToastMessage, isAuthenticated } = props;
  
  const onSubmit = () => {
    if (!isValidEmail(email)) {
      setToastMessage({ msg: "Please enter your email address", type: TOAST_ALERT });
      return;
    }
    if (isEmpty(password)) {
      setToastMessage({ msg: "Please enter your password", type: TOAST_ALERT });
      return;
    }
    signInWithEmailAndPassword({ email, password });
    Router.to("/exclusive-events");
  };

  return (
    <Container>
      <input className="form-control" type="text" placeholder="Email address..." value={email} onChange={(e) => setAuthState({email: e.target.value})} required />
      <input className="form-control" type="password" placeholder="Password" value={password} onChange={(e) => setAuthState({password: e.target.value})} required />
      <RdvButton
        // isSubmitting={inProgress}
        onClick={onSubmit}
        text="Submit"
        isLoading={inProgress}
        disabled={isEmpty(email) || isEmpty(password)}
        style={{width: "100%", margin: "0", marginBottom: "5px", borderRadius: "15px" }}
      />
      <div className="text-center">
        <a href="#">Need Help?</a>
      </div>
    </Container>
  )
}

const Container = ({children}: {children: React.ReactNode}) => {
  return (
    <div id="exclusive-event" className="exclusive-event-form">
      {children}
    </div>
  )
}

const mapState = (state: AppState) => ({
  email: state.authentication.email,
  password: state.authentication.password,
  inProgress: state.cache.inProgress["signin"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  signInWithEmailAndPassword: Actions.signInWithEmailAndPassword,
  setToastMessage: Actions.setToastMessage,
};

export const ExclusiveEventForm = connect(mapState, mapDispatch)(Component);
