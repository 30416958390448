import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  UpdateAccountTypeAction,
  UpdateAccountTypePayload,
} from "../../../redux/authentication/types";
import { Submit } from "../../../react-components/modals/modal-form-elements";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import {
  AUTH_CHOOSE_ACCOUNT_TYPE,
  ACCOUNT_TYPES,
  AUTH_WELCOME_TO_RADIVISION,
} from "../../../redux/authentication/constants";
import { SetProfileStateAction, SetProfileStatePayload } from "../redux/profile/types";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { ENTHUSIAST, FOUNDER, INVESTOR } from "../../../redux/authentication/account-types";
import isEmpty from "lodash.isempty";
import { setProfileState, updateAccountType } from "../redux/profile/actions";
import { AccountType } from "../../../redux/types/account-type";

interface MapDispatchProps {
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
  updateAccountType: (payload: UpdateAccountTypePayload) => UpdateAccountTypeAction;
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

interface MapStateProps {
  user: Person & { accountType: AccountType };
  step: number;
  accountType: AccountType;
  inProgress: boolean;
  signupAccountTypes: string[];
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const {
    step,
    user,
    accountType,
    inProgress,
    setAuthState,
    setProfileState,
    setToastMessage,
    signupAccountTypes,
  } = props;
  const shouldDisplay = step === AUTH_CHOOSE_ACCOUNT_TYPE;

  const onSubmit = React.useCallback(() => {
    if (isEmpty(accountType?.kind)) {
      setToastMessage({ msg: "Please choose your account type", type: TOAST_ALERT });
      return;
    }
    setAuthState({ step: AUTH_WELCOME_TO_RADIVISION });
  }, [accountType, setAuthState, setToastMessage]);

  React.useEffect(() => {
    if (isEmpty(accountType?.kind) && !isEmpty(user?.accountType)) {
      setProfileState({ accountType: user.accountType });
    }
  }, [user, accountType, setProfileState]);

  const onClick = React.useCallback(
    (accountType) => {
      setAuthState({ accountType });
      if ([FOUNDER.kind, INVESTOR.kind, ENTHUSIAST.kind].includes(accountType)) {
        setProfileState({ id: user?.id, accountType });
      }
    },
    [setAuthState, user, setProfileState],
  );

  const Card = React.useCallback(
    (props) => {
      const { title, description, kind, colors } = props;
      const isActive = accountType?.kind === kind;
      // const backgroundImage = isActive ? `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)` : "none";
      const backgroundImage = isActive
        ? `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 60%, ${colors[2]} 90%)`
        : "none";
      return (
        <li
          onClick={() => onClick({ kind })}
          style={{ backgroundImage }}
          className={`account-type-card ${isActive ? "is-active" : ""}`}
        >
          <h3>{title}</h3>
          <p>{description}</p>
        </li>
      );
    },
    [onClick, accountType],
  );

  if (!shouldDisplay) {
    return null;
  }

  const accountTypes = signupAccountTypes?.length > 0 ? signupAccountTypes : ACCOUNT_TYPES;
  return (
    <Container>
      {/* <div className="subtitle">
        Select your account type below. Company profiles can be created after your personal profile is set up.
      </div> */}
      <ul className="account-types">
        {accountTypes.map((accType) => {
          return <Card key={`account-card-${accType.kind}`} {...accType} />;
        })}
      </ul>
      <Submit
        onClick={onSubmit}
        disabled={false}
        className={isEmpty(accountType) ? "top-mc disabled" : "top-mc"}
        isSubmitting={inProgress}
        title={"Continue"}
      />
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="choose-account-type">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  user: state.account.user,
  accountType: state.authentication.accountType,
  inProgress: state.cache.inProgress["update-account-type"],
  signupAccountTypes: state.authentication.signupAccountTypes,
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  setToastMessage: Actions.setToastMessage,
  setProfileState: setProfileState,
  updateAccountType: updateAccountType,
};

export const ChooseAccountType = connect(mapState, mapDispatch)(Component);
