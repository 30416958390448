export const CompanyTypes = [
  //  { id: "1", title: "C-Corporation", kind: "C-Corporation" },
  //  { id: "2", title: "LLC", kind: "LLC" },
  //  { id: "3", title: "S-Corporation", kind: "S-Corporation" },
  //  { id: "4", title: "Partnership", kind: "PARTNERSHIP" },
  { id: "1", title: "C-Corporation", kind: "EDUCATIONAL" },
  { id: "2", title: "LLC", kind: "GOVERNMENT_AGENCY" },
  { id: "3", title: "S-Corporation", kind: "NON_PROFIT" },
  { id: "4", title: "Partnership", kind: "PARTNERSHIP" },
  //  { id: "5", title: "Privately Held", kind: "PRIVATELY_HELD" },
  //  { id: "6", title: "Public Company", kind: "PUBLIC_COMPANY" },
  //  { id: "7", title: "Self-Employed", kind: "SELF_EMPLOYED" },
  // { id: "7", title: "Self Owned", kind: "SELF_OWNED" },
];

export const CompanyCountries = [
  { id: 1, name: "Afghanistan" },
  { id: 2, name: "Albania" },
  { id: 3, name: "Algeria" },
  { id: 4, name: "Andorra" },
  { id: 5, name: "Angola" },
  { id: 6, name: "Antigua and Barbuda" },
  { id: 7, name: "Argentina" },
  { id: 8, name: "Armenia" },
  { id: 9, name: "Australia" },
  { id: 10, name: "Austria" },
  { id: 11, name: "Azerbaijan" },
  { id: 12, name: "Bahamas" },
  { id: 13, name: "Bahrain" },
  { id: 14, name: "Bangladesh" },
  { id: 15, name: "Barbados" },
  { id: 16, name: "Belarus" },
  { id: 17, name: "Belgium" },
  { id: 18, name: "Belize" },
  { id: 19, name: "Benin" },
  { id: 20, name: "Bhutan" },
  { id: 21, name: "Bolivia" },
  { id: 22, name: "Bosnia and Herzegovina" },
  { id: 23, name: "Botswana" },
  { id: 24, name: "Brazil" },
  { id: 25, name: "Brunei" },
  { id: 26, name: "Bulgaria" },
  { id: 27, name: "Burkina Faso" },
  { id: 28, name: "Burundi" },
  { id: 29, name: "Cabo Verde" },
  { id: 30, name: "Cambodia" },
  { id: 31, name: "Cameroon" },
  { id: 32, name: "Canada" },
  { id: 33, name: "Cayman Islands" },
  { id: 34, name: "Central African Republic" },
  { id: 35, name: "Chad" },
  { id: 36, name: "Chile" },
  { id: 37, name: "China" },
  { id: 38, name: "Colombia" },
  { id: 39, name: "Comoros" },
  { id: 40, name: "Congo, Democratic Republic of the" },
  { id: 41, name: "Congo, Republic of the" },
  { id: 42, name: "Costa Rica" },
  { id: 43, name: "Cote d'Ivoire" },
  { id: 44, name: "Croatia" },
  { id: 45, name: "Cuba" },
  { id: 46, name: "Cyprus" },
  { id: 47, name: "Czech Republic (Czechia)" },
  { id: 48, name: "Denmark" },
  { id: 49, name: "Djibouti" },
  { id: 50, name: "Dominica" },
  { id: 51, name: "Dominican Republic" },
  { id: 52, name: "East Timor (Timor-Leste)" },
  { id: 53, name: "Ecuador" },
  { id: 54, name: "Egypt" },
  { id: 55, name: "El Salvador" },
  { id: 56, name: "Equatorial Guinea" },
  { id: 57, name: "Eritrea" },
  { id: 58, name: "Estonia" },
  { id: 59, name: "Eswatini (formerly Swaziland)" },
  { id: 60, name: "Ethiopia" },
  { id: 61, name: "Fiji" },
  { id: 62, name: "Finland" },
  { id: 63, name: "France" },
  { id: 64, name: "Gabon" },
  { id: 65, name: "Gambia" },
  { id: 66, name: "Georgia" },
  { id: 67, name: "Germany" },
  { id: 68, name: "Ghana" },
  { id: 69, name: "Greece" },
  { id: 70, name: "Grenada" },
  { id: 71, name: "Guatemala" },
  { id: 72, name: "Guinea" },
  { id: 73, name: "Guinea-Bissau" },
  { id: 74, name: "Guyana" },
  { id: 75, name: "Haiti" },
  { id: 76, name: "Honduras" },
  { id: 77, name: "Hungary" },
  { id: 78, name: "Iceland" },
  { id: 79, name: "India" },
  { id: 80, name: "Indonesia" },
  { id: 81, name: "Iran" },
  { id: 82, name: "Iraq" },
  { id: 83, name: "Ireland" },
  { id: 84, name: "Israel" },
  { id: 85, name: "Italy" },
  { id: 86, name: "Jamaica" },
  { id: 87, name: "Japan" },
  { id: 88, name: "Jordan" },
  { id: 89, name: "Kazakhstan" },
  { id: 90, name: "Kenya" },
  { id: 91, name: "Kiribati" },
  { id: 92, name: "Korea, North" },
  { id: 93, name: "Korea, South" },
  { id: 94, name: "Kosovo" },
  { id: 95, name: "Kuwait" },
  { id: 96, name: "Kyrgyzstan" },
  { id: 97, name: "Laos" },
  { id: 98, name: "Latvia" },
  { id: 99, name: "Lebanon" },
  { id: 100, name: "Lesotho" },
  { id: 101, name: "Liberia" },
  { id: 102, name: "Libya" },
  { id: 103, name: "Liechtenstein" },
  { id: 104, name: "Lithuania" },
  { id: 105, name: "Luxembourg" },
  { id: 106, name: "Madagascar" },
  { id: 107, name: "Malawi" },
  { id: 108, name: "Malaysia" },
  { id: 109, name: "Maldives" },
  { id: 110, name: "Mali" },
  { id: 111, name: "Malta" },
  { id: 112, name: "Marshall Islands" },
  { id: 113, name: "Mauritania" },
  { id: 114, name: "Mauritius" },
  { id: 115, name: "Mexico" },
  { id: 116, name: "Micronesia" },
  { id: 117, name: "Moldova" },
  { id: 118, name: "Monaco" },
  { id: 119, name: "Mongolia" },
  { id: 120, name: "Montenegro" },
  { id: 121, name: "Morocco" },
  { id: 122, name: "Mozambique" },
  { id: 123, name: "Myanmar (Burma)" },
  { id: 124, name: "Namibia" },
  { id: 125, name: "Nauru" },
  { id: 126, name: "Nepal" },
  { id: 127, name: "Netherlands" },
  { id: 128, name: "New Zealand" },
  { id: 129, name: "Nicaragua" },
  { id: 130, name: "Niger" },
  { id: 131, name: "Nigeria" },
  { id: 132, name: "North Macedonia (formerly Macedonia)" },
  { id: 133, name: "Norway" },
  { id: 134, name: "Oman" },
  { id: 135, name: "Pakistan" },
  { id: 136, name: "Palau" },
  { id: 137, name: "Palestine" },
  { id: 138, name: "Panama" },
  { id: 139, name: "Papua New Guinea" },
  { id: 140, name: "Paraguay" },
  { id: 141, name: "Peru" },
  { id: 142, name: "Philippines" },
  { id: 143, name: "Poland" },
  { id: 144, name: "Portugal" },
  { id: 145, name: "Qatar" },
  { id: 146, name: "Romania" },
  { id: 147, name: "Russia" },
  { id: 148, name: "Rwanda" },
  { id: 149, name: "Saint Kitts and Nevis" },
  { id: 150, name: "Saint Lucia" },
  { id: 151, name: "Saint Vincent and the Grenadines" },
  { id: 152, name: "Samoa" },
  { id: 153, name: "San Marino" },
  { id: 154, name: "Sao Tome and Principe" },
  { id: 155, name: "Saudi Arabia" },
  { id: 156, name: "Senegal" },
  { id: 157, name: "Serbia" },
  { id: 158, name: "Seychelles" },
  { id: 159, name: "Sierra Leone" },
  { id: 160, name: "Singapore" },
  { id: 161, name: "Slovakia" },
  { id: 162, name: "Slovenia" },
  { id: 163, name: "Solomon Islands" },
  { id: 164, name: "Somalia" },
  { id: 165, name: "South Africa" },
  { id: 166, name: "South Sudan" },
  { id: 167, name: "Spain" },
  { id: 168, name: "Sri Lanka" },
  { id: 169, name: "Sudan" },
  { id: 170, name: "Suriname" },
  { id: 171, name: "Sweden" },
  { id: 172, name: "Switzerland" },
  { id: 173, name: "Syria" },
  { id: 174, name: "Taiwan" },
  { id: 175, name: "Tajikistan" },
  { id: 176, name: "Tanzania" },
  { id: 177, name: "Thailand" },
  { id: 178, name: "Togo" },
  { id: 179, name: "Tonga" },
  { id: 180, name: "Trinidad and Tobago" },
  { id: 181, name: "Tunisia" },
  { id: 182, name: "Turkey" },
  { id: 183, name: "Turkmenistan" },
  { id: 184, name: "Tuvalu" },
  { id: 185, name: "Uganda" },
  { id: 186, name: "Ukraine" },
  { id: 187, name: "United Arab Emirates" },
  { id: 188, name: "United Kingdom" },
  { id: 189, name: "United States" },
  { id: 190, name: "Uruguay" },
  { id: 191, name: "Uzbekistan" },
  { id: 192, name: "Vanuatu" },
  { id: 193, name: "Vatican City" },
  { id: 194, name: "Venezuela" },
  { id: 195, name: "Vietnam" },
  { id: 196, name: "Yemen" },
  { id: 197, name: "Zambia" },
  { id: 198, name: "Zimbabwe" },
];

export const CompanyInvestmentStages = [
  { id: "1", title: "Pre-Seed" },
  { id: "2", title: "Seed" },
  { id: "3", title: "Series A" },
  { id: "4", title: "Series B" },
  { id: "5", title: "Series C & Above" },
  { id: "6", title: "IPO" },
];

export const CompanyIndustries = [
  { id: "1", title: "3D Printing" },
  { id: "1", title: "Aerospace & Defense" },
  { id: "1", title: "Agriculture & Forestry" },
  { id: "1", title: "Agritech" },
  { id: "1", title: "Apparel & Fashion" },
  { id: "1", title: "Architecture & Planning" },
  { id: "1", title: "Art & Culture" },
  { id: "1", title: "Art Tech" },
  { id: "1", title: "Artificial Intelligence (AI)" },
  { id: "1", title: "Augmented Reality (AR)" },
  { id: "1", title: "Automotive" },
  { id: "1", title: "Autonomous Vehicles" },
  { id: "1", title: "Aviation" },
  { id: "1", title: "Banking & Finance" },
  { id: "1", title: "Beauty & Personal Care" },
  { id: "1", title: "Biomedical Engineering" },
  { id: "1", title: "Biotechnology" },
  { id: "1", title: "Blockchain" },
  { id: "1", title: "Chemicals" },
  { id: "1", title: "Civic Tech" },
  { id: "1", title: "Clean Energy Technology" },
  { id: "1", title: "Cloud Computing" },
  { id: "1", title: "Computer Hardware" },
  { id: "1", title: "Computer Software" },
  { id: "1", title: "Construction & Real Estate" },
  { id: "1", title: "Consulting" },
  { id: "1", title: "Consumer Electronics" },
  { id: "1", title: "Consumer Goods & Services" },
  { id: "1", title: "Cryptocurrency" },
  { id: "1", title: "Cybersecurity" },
  { id: "1", title: "Data Analytics" },
  { id: "1", title: "Digital Marketing" },
  { id: "1", title: "E-commerce" },
  { id: "1", title: "Edtech" },
  { id: "1", title: "Education" },
  { id: "1", title: "Education Tech" },
  { id: "1", title: "Energy & Utilities" },
  { id: "1", title: "Entertainment & Media" },
  { id: "1", title: "Entertainment Tech" },
  { id: "1", title: "Environmental Services" },
  { id: "1", title: "Fashion Tech" },
  { id: "1", title: "Financial Services" },
  { id: "1", title: "Financial Technology (Fintech)" },
  { id: "1", title: "Fitness Tech" },
  { id: "1", title: "Food & Beverage" },
  { id: "1", title: "Food Tech" },
  { id: "1", title: "Gaming" },
  { id: "1", title: "Gaming Tech" },
  { id: "1", title: "Genetic Engineering" },
  { id: "1", title: "Government Administration" },
  { id: "1", title: "Govtech" },
  { id: "1", title: "Green Technology" },
  { id: "1", title: "Hardware as a Service (HaaS)" },
  { id: "1", title: "Healthcare & Pharmaceuticals" },
  { id: "1", title: "Healthtech" },
  { id: "1", title: "Hospitality & Tourism" },
  { id: "1", title: "Human Resources" },
  { id: "1", title: "Information Technology" },
  { id: "1", title: "Insurance" },
  { id: "1", title: "Insurtech" },
  { id: "1", title: "Internet of Things (IoT)" },
  { id: "1", title: "Legal Services" },
  { id: "1", title: "Legaltech" },
  { id: "1", title: "Logistics Tech" },
  { id: "1", title: "Manufacturing" },
  { id: "1", title: "Manufacturing Tech" },
  { id: "1", title: "Marketing & Advertising" },
  { id: "1", title: "Media Tech" },
  { id: "1", title: "Mining & Metals" },
  { id: "1", title: "Mobile App Development" },
  { id: "1", title: "Music & Performing Arts" },
  { id: "1", title: "Music Tech" },
  { id: "1", title: "Nanotechnology" },
  { id: "1", title: "Nonprofit Organization Management" },
  { id: "1", title: "Oil & Energy" },
  { id: "1", title: "Public Relations & Communications" },
  { id: "1", title: "Publishing" },
  { id: "1", title: "Regtech" },
  { id: "1", title: "Renewable & Alternative Energy" },
  { id: "1", title: "Research" },
  { id: "1", title: "Retail" },
  { id: "1", title: "Retail Tech" },
  { id: "1", title: "Robotics" },
  { id: "1", title: "Security & Investigations" },
  { id: "1", title: "Smart Home Technology" },
  { id: "1", title: "Social Media Management" },
  { id: "1", title: "Software as a Service (SaaS)" },
  { id: "1", title: "Space Tech" },
  { id: "1", title: "Sports" },
  { id: "1", title: "Sports Tech" },
  { id: "1", title: "Staffing & Recruiting" },
  { id: "1", title: "Technology Investment" },
  { id: "1", title: "Telecommunications" },
  { id: "1", title: "Translation & Localization" },
  { id: "1", title: "Transportation & Logistics" },
  { id: "1", title: "Travel Tech" },
  { id: "1", title: "Veterinary" },
  { id: "1", title: "Virtual Reality (VR)" },
  { id: "1", title: "Wearable Technology" },
  { id: "1", title: "Web Development" },
];

export const CompanySizeRanges = ["1 - 10", "11 - 50", "51 - 100", "101 - 200", "200+"];
export const CompanySizeRangesFilters = [
  {
    id: 1,
    title: "1 - 10",
  },
  {
    id: 2,
    title: "11 - 50",
  },
  {
    id: 3,
    title: "51 - 100",
  },
  {
    id: 4,
    title: "101 - 200",
  },
  {
    id: 5,
    title: "200+",
  },
];
