import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { AUTH_CHOOSE_ACCOUNT_TYPE } from "../../../redux/authentication/constants";
import { ChooseAccountType as ChooseAccountTypeCore } from "../../../modules/manage-profile/choose-account-type";
interface MapStateProps {
  step: number;
}

export type Props = MapStateProps;
const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });
  await Promise.all(promises);
};

export const Component = (props: Props) => {

  const IMAGES = [
    "https://content.radivision.com/images/colored_balloons.jpg?wip=720",
    "https://content.radivision.com/images/grey_balloons.jpg?wip=420",
  ];
  cacheImages(IMAGES);

  const { step } = props;
  const isChooseAccountStep = step === AUTH_CHOOSE_ACCOUNT_TYPE;
  const [greyScale, setgreyScaled] = React.useState(false);
  if (!isChooseAccountStep) return null;
  // const DynamicChooseAccountType = Loadable({
  //   loader: () => import("../../../modules/manage-profile").then((module) => module.ChooseAccountType) as Promise<any>,
  //   loading: () => <Loader isActive={true} />,
  // });
  return (

    <>
      <div className="subtitle">
        Select your account type below. Company profiles can be created after your personal profile set up.
      </div>

      <div className="dynamic-choose-account-type"
        style={{
          backgroundColor: "rgba(23,23,23,1)",
          backgroundImage: `url('${!greyScale ? IMAGES[0] : IMAGES[1]}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "25px",
          backgroundPosition: "center center";

        }}
        onClick={() => {
          setgreyScaled(true);
        }}>


        <ChooseAccountTypeCore />
      </div>
    </>
  );
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
});

const mapDispatch = {};

export const ChooseAccountType = connect(mapState, mapDispatch)(Component);
