import * as React from "react";
import Loadable from "react-loadable";
import OptionsWithoutRender = LoadableExport.OptionsWithoutRender;
import { Loader } from "../../page/loader";

interface ComponentProps {
}

type Props = ComponentProps;

const loadableOptions: OptionsWithoutRender<Props> = {
  loader: () => import("./sponsor-panel").then((module) => module.SponsorPanel) as Promise<any>,
  loading: () => <Loader isActive={true} />,
};

export const SponsorPanel = Loadable(loadableOptions);

