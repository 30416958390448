import React from "react";
import { AUTH_SIGNUP, AUTHENTICATION_MODAL_ID } from "../../../redux/authentication/constants";
import { store } from "../../../redux";
import { setAuthState } from "../../../redux/actions";

export const RevolutionSection = () => {
  return (
    <section className="revolution-section">
      <div className="revolution-content">
        <h1 className="revolution-heading">The Entrepreneurial Revolution is Here</h1>
        <p className="revolution-subheading">Join now. Don’t be left behind.</p>
        <div className="getting-started-header" style={{ zIndex: 1000 }}>
          <div className="gs-btn" style={{ margin: "0px 0px 35px 0px" }}>
            <button
              data-toggle="modal"
              data-backdrop="static"
              data-keyboard="false"
              data-target={`#${AUTHENTICATION_MODAL_ID}`}
              onClick={() => {
                store.dispatch(setAuthState({ step: AUTH_SIGNUP }));
              }}
            >
              Join
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
