import React, { useMemo } from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  CompanyIndustries,
  CompanyInvestmentStages,
  CompanySizeRangesFilters,
  CompanyTypes,
} from "../../../constants/company-info";
import { SetPitchPlanetFilterPayload, SetPitchPlanetFilterAction } from "../../../redux/account/types";
import { Checkbox } from "../../page-framework/checkbox";
import { SVG } from "../../../component-configuration/svgs";
import { SearchFacet } from "@radivision/graphql/lib/ts/graphql/search-hit";

interface ComponentProps {
  facets?: SearchFacet[];
}

interface MapDispatchProps {
  setPitchPlanetFilter: (payload: SetPitchPlanetFilterPayload) => SetPitchPlanetFilterAction;
}
interface MapStateProps {
  filters: Record<string, string[]>;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

const SKIP_FACETS_NAMES: string[] = ["activity"];

const generateMenus = (facets: SearchFacet[] | undefined) => {
  const menus = {
    industry: CompanyIndustries,
    stage: CompanyInvestmentStages,
    size: CompanySizeRangesFilters,
  };

  facets?.forEach((facet) => {
    const bucketKey = facet.name;

    if (!SKIP_FACETS_NAMES.includes(bucketKey) && bucketKey) {
      menus[bucketKey] = facet.buckets.map((item, index) => ({
        id: index,
        title: item.value,
        count: item.count,
      }));
    }
  });

  return menus;
};

export const Component = ({ filters, setPitchPlanetFilter, facets }: Props) => {
  const menus = useMemo(() => generateMenus(facets), [facets]);

  const handleCheckboxChange = (key: string, value: string) => () => {
    setPitchPlanetFilter({ key, value });
  };

  return (
    <div className="pitch-planet-filters">
      {Object.entries(menus).map(([filterName, menu]) => {
        const filter = filters[filterName] || [];

        return (
          <div className="filter-tab" key={`filter-tab-${filterName}`}>
            <a className="filter-name">
              <span>{filterName === "activity" ? "Company Type" : filterName}</span> {SVG.caretRight}
            </a>
            <ul className="sm-scroller">
              {menu.map(
                (item: any, index: number) =>
                  item?.title && (
                    <li key={`filter-${filterName}-${index}`}>
                      <Checkbox
                        checked={filter.includes(item.title)}
                        onChange={handleCheckboxChange(filterName, item.title)}
                        label={
                          <div>
                            <span>{item.title}</span>
                            {item.count && <span>{item.count > 99 ? "99+" : item.count}</span>}
                          </div>
                        }
                      />
                    </li>
                  ),
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: AppState): MapStateProps => ({
  filters: state.account.pitchPlanetFilter,
});

const mapDispatchToProps: MapDispatchProps = {
  setPitchPlanetFilter: Actions.setPitchPlanetFilter,
};

export const PitchPlanetFilters = connect(mapStateToProps, mapDispatchToProps)(Component);
